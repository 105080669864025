import * as Yup from 'yup';
import { useContext, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Link,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  CircularProgress
} from '@mui/material';
import useAuth from 'src/shared/hooks/useAuth';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const LoginForm: FC = () => {
  const { login } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [errorsToBackEnd, setErrorsToBackend] = useState('');

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={{
        email:
          process.env.REACT_APP_ENV === 'development'
            ? 'manager1@aspirans.az'
            : '',
        password: process.env.REACT_APP_ENV === 'development' ? '123456' : '',
        terms: true,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('yup.messages.email'))
          //
          .max(255)
          .required(t('yup.required.email')),
        password: Yup.string().max(255).required(t('yup.required.password')),
        terms: Yup.boolean().oneOf([true], t('yup.required.terms'))
      })}
      onSubmit={async (values, { setStatus, setSubmitting }): Promise<void> => {
        try {
          await login(values.email, values.password);
          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrorsToBackend(t('yup.messages.invalidCredentials'));

          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            label={t('inputs.label.email')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          {Boolean(touched.email && errors.email) && (
            <FormHelperText error>{errors.email}</FormHelperText>
          )}
          <FormControl
            sx={{ width: '100%' }}
            variant="outlined"
            margin="normal"
            error={Boolean(touched.password && errors.password)}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {t('inputs.label.password')}
            </InputLabel>
            <OutlinedInput
              name="password"
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            {Boolean(touched.password && errors.password) && (
              <FormHelperText error>{errors.password}</FormHelperText>
            )}
          </FormControl>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={values.terms}
                  name="terms"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t('I accept the')}{' '}
                    <Link component="a" href="#">
                      {t('terms and conditions')}
                    </Link>
                    .
                  </Typography>
                </>
              }
            /> */}
            <Link
              component={RouterLink}
              to="/recover-password"
              sx={{ textAlign: 'right', width: '100%' }}
            >
              <b>{t('inputs.label.lostPassword')}</b>
            </Link>
          </Box>

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )}

          {errorsToBackEnd && (
            <FormHelperText
              sx={{
                ml: 0,
                position: 'absolute',
                bottom: '20px',
                left: '20px',
                color: 'white',
                background: 'red',
                padding: '12px 20px',
                borderRadius: '8px',
                fontWeight: '700',
                fontSize: '13px'
              }}
            >
              {errorsToBackEnd}
            </FormHelperText>
          )}

          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t('signin.title')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
