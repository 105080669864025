import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';
import { addAndUpdateSubUser } from 'src/modules/user-services/services';
import { useEmployee } from './use-employee';
import { useSubjects } from 'src/modules/subjects/hooks/use-subjects';
import { employeeFormMapper } from '../mappers';
import { REGULAR_EXPRESSIONS } from 'src/shared/costants/constants';
import { useLowerCategoriesByUpperSlug } from 'src/modules/lower-categories/hooks/use-lower-categories-by-upper-slug';

export const useEmployeeForm = function () {
  const { t } = useTranslation();
  const { handleMessage } = useContext(AlertContext);
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data } = useEmployee();
  const { data: categoriesByRoles } = useLowerCategoriesByUpperSlug('roles');
  const { data: categoriesByPositions } =
    useLowerCategoriesByUpperSlug('positions');
  const { data: subjectsData } = useSubjects(false);

  const { mutate: addAndEditEmployeeFormMutate, isLoading: isSubmitting } =
    useMutation('add-employee', addAndUpdateSubUser, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('employees-get-all');
          if (!employeeId) navigate('/dashboard/employees');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('yup.required.name')),
    email: Yup.string().required(t('yup.required.email')),
    gender: Yup.string().required(t('yup.required.gender')),
    surname: Yup.string().required(t('yup.required.surname')),
    isForeign: Yup.boolean(),
    phoneNumbers: Yup.string().when('isForeign', {
      is: false,
      then: Yup.string()
        .min(9, t('yup.required.minPhoneNumber'))
        .max(9, t('yup.required.maxPhoneNumber'))
        .matches(REGULAR_EXPRESSIONS.phone, t('yup.required.validPhoneNumber'))
        .required(),
      otherwise: Yup.string().required()
    })
  });

  const roles = data?.roles
    ?.filter((role) => role !== 'employee')
    ?.map((item) => ({ id: item, name: item }));

  const isForeign = data && data?.phoneNumbers.toString().slice(0, 3) !== '994';
  const phoneNumber = isForeign
    ? data?.phoneNumbers.toString()
    : data?.phoneNumbers.toString().slice(3);

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      surname: data?.surname || '',
      patronymic: data?.patronymic || '',
      description: data?.description || '',
      positions: data?.positions || [],
      note: '',
      phoneNumbers: phoneNumber || '',
      email: data?.email || '',
      dateOfBirth: data?.dateOfBirth ? dayjs(data?.dateOfBirth) : null,
      subjects: data?.subjects || [],
      roles: roles || [],
      isForeign: isForeign || false,
      gender: data?.gender || ''
    },
    onSubmit: (values: any) => {
      let mappedEmployee;
      if (employeeId) {
        mappedEmployee = employeeFormMapper(values, employeeId);
      } else {
        mappedEmployee = employeeFormMapper(values);
      }

      if (values) {
        if (employeeId) {
          addAndEditEmployeeFormMutate({
            data: mappedEmployee,
            userId: employeeId
          });
        } else {
          addAndEditEmployeeFormMutate({
            data: mappedEmployee,
            role: values?.roles?.id
          });
        }
      }
    },
    validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    isSubmitting,
    categoriesByRoles,
    categoriesByPositions,
    subjectsData,
    employeeId,
    data
  };
};
