import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useUpperCategoryForm } from '../hooks/use-upper-category-form';
import { AddEditUpperCategoryForm } from '../components/add-edit-upper-category-form';
import { styled } from '@mui/material';
import LoadingCircle from 'src/shared/components/Loading-circle';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const UpperCategoryContainer = function () {
  const { t } = useTranslation();
  const { formik, data, isSubmitting, upperCategoryId } =
    useUpperCategoryForm();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('upperCategories.name'),
      href: '/dashboard/upper-categories'
    },
    {
      label: t(
        `upperCategories.${
          upperCategoryId ? 'editUpperCategory' : 'addUpperCategory'
        }`
      ),
      href: '/dashboard/parents/add-upper-category'
    }
  ];

  if (!data && upperCategoryId) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }

  return (
    <PageWrapper
      routes={routes}
      title={
        upperCategoryId
          ? `${t('upperCategories.upperCategory')}: ${data?.id}`
          : t('upperCategories.addUpperCategory')
      }
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <AddEditUpperCategoryForm
            formik={formik}
            isSubmitting={isSubmitting}
            upperCategoryId={upperCategoryId}
            data={data}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
