import { Autocomplete, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useLowerCategoryForm } from '../hooks/use-lower-category-form';

function LowerCategorySearch() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { upperCategories } = useLowerCategoryForm();
  const formik = useFormik({
    initialValues: {
      upperCategory: ''
    },
    onSubmit: () => {}
  });

  let upperCategory = formik.values.upperCategory;

  const triggerSearch = useCallback(function (upperCategory) {
    if (upperCategory.trim() !== '') {
      searchParams.set('upperCategory', upperCategory);
    } else {
      searchParams.delete('upperCategory');
    }
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      triggerSearch(upperCategory);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [upperCategory]);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={6} sm={4}>
        <Autocomplete
          fullWidth
          options={upperCategories?.data || []}
          onChange={(_e, newValue: any) =>
            formik.setFieldValue('upperCategory', newValue ? newValue?.id : '')
          }
          value={
            upperCategories?.data?.find(
              (upperCategory) =>
                upperCategory.id === formik.values.upperCategory
            ) || null
          }
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label={t('inputs.label.upperCategory')}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default LowerCategorySearch;
