import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericService } from 'src/shared/services/generic-services';
import { TableContext } from 'src/shared/contexts/table-context';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { TLowerCategoriesRoot } from '../type';

export const useLowerCategories = function (paginated = true) {
  const [searchParams] = useSearchParams();
  const upperCategory = searchParams.get('upperCategory');

  const { page, limit } = useContext(TableContext);
  const { data, isLoading } = useQuery({
    queryKey: [
      'lower-categories-get-all',
      'lower-categories',
      { page, limit, upperCategory, paginated }
    ],
    queryFn: getGenericService,
    select: ({ data }: { data: TLowerCategoriesRoot }) => {
      if (data?.status === 'success') {
        return { data: data?.data, total: data?.total };
      }
    },
    onError: queryError
  });
  return { data, isLoading };
};
