import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { getGenericServiceById } from 'src/shared/services/generic-services';
import { queryError } from 'src/shared/utils/query-error';

export const useUpperCategory = () => {
  const { upperCategoryId } = useParams();

  const { data, isLoading } = useQuery(
    ['upper-categories', upperCategoryId],
    getGenericServiceById,
    {
      select: ({ data }) => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError
    }
  );
  return {
    data,
    isLoading
  };
};
