import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { LowerCategoryForm } from '../components/lower-category-form';
import { useLowerCategoryForm } from '../hooks/use-lower-category-form';
import { styled } from '@mui/material';
import LoadingCircle from 'src/shared/components/Loading-circle';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const LowerCategoryContainer = function () {
  const { t } = useTranslation();
  const { formik, data, isSubmitting, lowerCategoryId, upperCategories } =
    useLowerCategoryForm();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('lowerCategories.name'),
      href: '/dashboard/lower-categories'
    },
    {
      label: t(
        `lowerCategories.${
          lowerCategoryId ? 'editLowerCategory' : 'addLowerCategory'
        }`
      ),
      href: '/dashboard/parents/add-lower-category'
    }
  ];

  if (!data && lowerCategoryId) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }

  return (
    <PageWrapper
      routes={routes}
      title={
        lowerCategoryId
          ? `${t('lowerCategories.lowerCategory')}: ${data.id}`
          : t('lowerCategories.addLowerCategory')
      }
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <LowerCategoryForm
            formik={formik}
            isSubmitting={isSubmitting}
            lowerCategoryId={lowerCategoryId}
            upperCategories={upperCategories}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
