import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { OmInput } from 'src/shared/components';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/shared/hooks/useAuth';

export const AddEditUpperCategoryForm = function ({
  formik,
  data,
  isSubmitting,
  upperCategoryId
}) {
  const { t } = useTranslation();
  const { can } = useAuth();

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.name')}
            name="title"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <OmInput
            multiline={true}
            rows={3}
            formik={formik}
            label={t('inputs.label.description')}
            name="description"
          />
        </Grid>
        {!data && can('see-not-deletable') && (
          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              sx={{ flex: 1, whiteSpace: 'nowrap' }}
              label={`${t('inputs.label.notDeletable')}`}
              control={
                <Checkbox
                  onChange={(e) =>
                    formik.setFieldValue('notDeletable', e.target.checked)
                  }
                  checked={formik.values.notDeletable}
                />
              }
            />
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          fullWidth
          color="primary"
          sx={{ width: { xs: '100%', md: 200 } }}
        >
          {t(`buttons.${upperCategoryId ? 'update' : 'addUpperCategory'}`)}
        </LoadingButton>
      </Grid>
    </Box>
  );
};
