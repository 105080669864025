import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericService } from 'src/shared/services/generic-services';
import { TableContext } from 'src/shared/contexts/table-context';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { TUpperCategoriesRoot } from '../type';

export const useUpperCategories = function (paginated = true) {
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');

  const { page, limit } = useContext(TableContext);
  const { data, isLoading } = useQuery({
    queryKey: [
      'upper-categories-get-all',
      'upper-categories',
      { page, limit, name, paginated }
    ],
    queryFn: getGenericService,
    select: ({ data }: { data: TUpperCategoriesRoot }) => {
      if (data?.status === 'success') {
        return { data: data?.data, total: data?.total };
      }
    },
    onError: queryError
  });
  return { data, isLoading };
};
