import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { ILowerCategory } from '../type';
import { LowerCategoriesTableActionButtons } from '../components/lower-categories-table-action-buttons';

export const useLowerCategoriesTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();
  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('lowerCategories.name'),
      href: '/dashboard/lowerCategories'
    }
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'title',
        align: 'left',
        label: t('table.title'),
        extructor: (lowerCategory: ILowerCategory) => lowerCategory.title
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (lowerCategory: ILowerCategory) => {
          return (
            <LowerCategoriesTableActionButtons lowerCategory={lowerCategory} />
          );
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
