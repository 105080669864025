import { muiDateToString } from 'src/shared/utils/helpers';
import {
  IGuardainForm,
  IGuardainFormAPI,
  IGuardian,
  IGuardianDetails,
  IGuardianDetailsAPI,
  TGuardiansRoot,
  TGuardiansRootAPI
} from '../type';

export function guardianMapper(value: IGuardianDetailsAPI): IGuardian {
  const guardian = {
    id: value.id,
    code: value.code,
    fullName: value.fullName,
    email: value.email,
    name: value.name,
    archived: value.archived
  };

  return guardian;
}

export function guardianFormMapper(values: IGuardainFormAPI): IGuardainForm {
  const guardian = {
    name: values.name,
    surname: values.surname,
    patronymic: values.patronymic,
    description: values.description,
    note: values.note,
    phoneNumbers: values?.isForeign
      ? [values.phoneNumbers].flat()
      : [`994${values.phoneNumbers}`].flat(),
    email: values.email,
    dateOfBirth: muiDateToString(values?.dateOfBirth),
    gender: values.gender
  };

  return guardian;
}

export const detailsGuardianMapper = function (
  data: IGuardianDetailsAPI
): IGuardianDetails {
  const guardian = {
    code: data?.code,
    name: data?.name,
    surname: data?.surname,
    patronymic: data?.patronymic,
    description: data?.description,
    phoneNumbers: data?.phoneNumbers,
    email: data?.email,
    dateOfBirth: data?.dateOfBirth,
    fullName: data?.fullName,
    gender: data?.gender
  };
  return guardian;
};

export const guardiansMapper = function (
  data: TGuardiansRootAPI
): TGuardiansRoot {
  const guardians = data?.data?.map((guardian) => guardianMapper(guardian));
  const total = data?.total;
  return { data: guardians, total: total };
};
