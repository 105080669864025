import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { addAndUpdateSubUser } from 'src/modules/user-services/services';
import { useGuardian } from './use-guardian';
import { guardianFormMapper } from '../mappers';
import { REGULAR_EXPRESSIONS } from 'src/shared/costants/constants';
import dayjs from 'dayjs';

export const useGuardianForm = function () {
  const { handleMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();
  const { guardianId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGuardian();

  const { mutate: addAndEditGuardianFormMutate, isLoading: isSubmitting } =
    useMutation('guardian-form', addAndUpdateSubUser, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('guardians-get-all');
          if (!guardianId) navigate('/dashboard/guardians');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('yup.required.name')),
    surname: Yup.string().required(t('yup.required.surname')),
    gender: Yup.string().required(t('yup.required.gender')),
    email: Yup.string().required(t('yup.required.email')),
    isForeign: Yup.boolean(),
    phoneNumbers: Yup.string().when('isForeign', {
      is: false,
      then: Yup.string()
        .min(9, t('yup.required.minPhoneNumber'))
        .max(9, t('yup.required.maxPhoneNumber'))
        .matches(REGULAR_EXPRESSIONS.phone, t('yup.required.validPhoneNumber'))
        .required()
      // otherwise: Yup.string().required()
    })
  });

  const isForeign = data && data?.phoneNumbers.toString().slice(0, 3) !== '994';
  const phoneNumber = isForeign
    ? data?.phoneNumbers.toString()
    : data?.phoneNumbers.toString().slice(3);

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      surname: data?.surname || '',
      patronymic: data?.patronymic || '',
      description: data?.description || '',
      note: data?.note || '',
      phoneNumbers: phoneNumber || '',
      email: data?.email || '',
      dateOfBirth: data?.dateOfBirth ? dayjs(data?.dateOfBirth) : null,
      isForeign: isForeign || false,
      gender: data?.gender || ''
    },
    onSubmit: (values) => {
      const mappedGuardian = guardianFormMapper(values);
      if (values) {
        if (guardianId) {
          addAndEditGuardianFormMutate({
            data: mappedGuardian,
            userId: guardianId
          });
        } else {
          addAndEditGuardianFormMutate({
            data: mappedGuardian,
            role: 'guardian'
          });
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    isSubmitting,
    guardianId,
    data
  };
};
