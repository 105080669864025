import { UpperCategories } from '../pages/upper-categories';
import { UpperCategory } from '../pages/upper-category';
import { UpperCategoryDetails } from '../pages/upper-category-details';

const upperCategoriesRouter = {
  path: 'upper-categories',
  children: [
    {
      path: '',
      element: <UpperCategories />,
      children: []
    },
    // {
    //   path: 'add-upper-category',
    //   element: <UpperCategory />,
    //   children: []
    // },
    {
      path: ':upperCategoryId',
      element: <UpperCategoryDetails />,
      children: []
    },
    {
      path: 'edit-upper-category/:upperCategoryId',
      element: <UpperCategory />,
      children: []
    }
  ]
};
export default upperCategoriesRouter;
