import { useContext, useState } from 'react';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Link } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLowerCategories } from '../hooks/use-lower-categories';
import GenericTableV2 from 'src/shared/components/generic-table-v2';
import { useLowerCategoriesTable } from '../hooks/use-lower-categories-table';
import { TableContext } from 'src/shared/contexts/table-context';
import LowerCategorySearch from '../components/lower-category-search';

export const LowerCategoriesContainer = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const [columns, options, routes] = useLowerCategoriesTable();
  const { page, limit, setPage, setLimit } = useContext(TableContext);

  const handleFilter = () => {
    setIsOpen((s) => !s);
  };

  const { data } = useLowerCategories();

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('lowerCategories.name')}
      action={
        <Link
          to="/dashboard/lower-categories/add-lower-category"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">{t('buttons.addLowerCategory')}</Button>
        </Link>
      }
    >
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px'
        }}
      >
        <Box
          onClick={handleFilter}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>{t('inputs.label.filter')}</Typography>
          {!isOpen ? (
            <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />
          ) : (
            <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />
          )}
        </Box>
        {isOpen && <LowerCategorySearch />}
      </Card>
      <Card>
        <GenericTableV2
          rows={data?.data}
          rowsCount={data?.total}
          columns={columns}
          options={options}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </Card>
    </PageWrapper>
  );
};
