import { Outlet } from 'react-router';
import TableContextProvider from 'src/shared/provider/table-context-provider';
import { LowerCategoriesContainer } from '../containers/lower-categories-container';

export function LowerCategories() {
  return (
    <TableContextProvider>
      <LowerCategoriesContainer />
      <Outlet />
    </TableContextProvider>
  );
}
