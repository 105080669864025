import { Outlet } from 'react-router';
import TableContextProvider from 'src/shared/provider/table-context-provider';
import { LowerCategoryContainer } from '../containers/lower-category-container';

export function LowerCategory() {
  return (
    <TableContextProvider>
      <LowerCategoryContainer />
      <Outlet />
    </TableContextProvider>
  );
}
