import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { ILesson } from '../types';
import { LessonsTableActionButtons } from '../components/lessons-table-action-buttons';

export const useLessonsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();
  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('lessons.name'),
      href: '/dashboard/lessons'
    }
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      // {
      //   id: 'code',
      //   align: 'left',
      //   label: t('table.code'),
      //   extructor: (lesson: ILesson) => lesson?.code
      // },
      {
        id: 'teacher',
        align: 'center',
        label: t('table.teacher'),
        extructor: (lesson: ILesson) => lesson?.teacher?.fullName
      },
      {
        id: 'group',
        align: 'center',
        label: t('table.group'),
        extructor: (lesson: ILesson) => lesson?.group?.name
      },
      {
        id: 'topic',
        align: 'center',
        label: t('table.subject'),
        extructor: (lesson: ILesson) => lesson?.subject?.name
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (lesson: ILesson) => {
          return <LessonsTableActionButtons lesson={lesson} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
