import { LowerCategories } from '../pages/lower-categories';
import { LowerCategory } from '../pages/lower-category';
import { LowerCategoryDetails } from '../pages/lower-category-details';

const lowerCategoriesRouter = {
  path: 'lower-categories',
  children: [
    {
      path: '',
      element: <LowerCategories />,
      children: []
    },
    {
      path: 'add-lower-category',
      element: <LowerCategory />,
      children: []
    },
    {
      path: ':lowerCategoryId',
      element: <LowerCategoryDetails />,
      children: []
    },
    {
      path: 'edit-lower-category/:lowerCategoryId',
      element: <LowerCategory />,
      children: []
    }
  ]
};
export default lowerCategoriesRouter;
