import { Outlet } from 'react-router';
import TableContextProvider from 'src/shared/provider/table-context-provider';
import { UpperCategoriesContainer } from '../containers/upper-categories-container';

export function UpperCategories() {
  return (
    <TableContextProvider>
      <UpperCategoriesContainer />
      <Outlet />
    </TableContextProvider>
  );
}
