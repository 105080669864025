import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router';
import { addAndUpdateGenericService } from 'src/shared/services/generic-services';
import { useLowerCategory } from './use-lower-category';
import { useUpperCategories } from 'src/modules/upper-categories/hooks/use-upper-categories';

export const useLowerCategoryForm = function () {
  const { t } = useTranslation();
  const { handleMessage } = useContext(AlertContext);
  const { lowerCategoryId } = useParams();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data: upperCategories } = useUpperCategories(false);

  const { data } = useLowerCategory();

  const { mutate: addAndEditLowerCategoryMutate, isLoading: isSubmitting } =
    useMutation('add-lower-category', addAndUpdateGenericService, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('lower-categories-get-all');
          if (!lowerCategoryId) navigate('/dashboard/lower-categories');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('yup.required.title'))
  });

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
      upperCategory: data?.upperCategory || '',
      description: data?.description || '',
      priority: data?.priority || ''
    },
    onSubmit: (values) => {
      if (values) {
        if (lowerCategoryId) {
          addAndEditLowerCategoryMutate({
            type: 'lower-categories',
            genericId: lowerCategoryId,
            data: values
          });
        } else {
          addAndEditLowerCategoryMutate({
            data: values,
            type: 'lower-categories'
          });
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    data,
    isSubmitting,
    lowerCategoryId,
    upperCategories
  };
};
