import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { addAndUpdateGenericService } from 'src/shared/services/generic-services';
import { useUpperCategory } from './use-upper-category';

export const useUpperCategoryForm = function () {
  const { handleMessage } = useContext(AlertContext);
  const { upperCategoryId } = useParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data } = useUpperCategory();

  const { mutate: addAndEditUpperCategoryMutate, isLoading: isSubmitting } =
    useMutation('add-upper-category', addAndUpdateGenericService, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('upper-categories-get-all');
          if (!upperCategoryId) navigate('/dashboard/upper-categories');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('yup.required.title'))
  });

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
      description: data?.description || '',
      notDeletable: false
    },
    onSubmit: (values) => {
      if (values) {
        if (upperCategoryId) {
          addAndEditUpperCategoryMutate({
            type: 'upper-categories',
            genericId: upperCategoryId,
            data: values
          });
        } else {
          addAndEditUpperCategoryMutate({
            data: values,
            type: 'upper-categories'
          });
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    data,
    isSubmitting,
    upperCategoryId
  };
};
