import { AxiosResponse } from 'axios';
import axios from 'src/shared/utils/axios';
import getAuthToken from 'src/shared/utils/getAuthToken';
import queryStringMaker from 'src/shared/utils/query-string-maker';

export const getUsers = async (query): Promise<AxiosResponse> => {
  const [, queryObj, role] = query.queryKey;

  const url = role
    ? `/api/v1/users/role/${role}${queryStringMaker(queryObj)}`
    : `/api/v1/users${queryStringMaker(queryObj)}`;

  try {
    return await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const getUsersForRoleService = async (query): Promise<AxiosResponse> => {
  const [, moduleType, queryObj] = query.queryKey;

  try {
    return await axios.get(
      `/api/v1/users/role/${moduleType}${queryStringMaker(queryObj)}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`
        }
      }
    );
  } catch (e) {
    return e;
  }
};

export const addAndUpdateSubUser = async ({
  data,
  userId = null,
  role = null
}): Promise<AxiosResponse> => {
  const url = userId ? `/api/v1/users/${userId}` : `/api/v1/users/role/${role}`;
  try {
    return await axios({
      method: userId ? 'patch' : 'post',
      url,
      data,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
  } catch (e) {
    return e;
  }
};

export const addAndUpdateUser = async ({
  data,
  userId = null,
  role = null
}): Promise<AxiosResponse> => {
  const url = userId ? `/api/v1/users/${userId}` : `/api/v1/users`;
  try {
    return await axios({
      method: userId ? 'patch' : 'post',
      url,
      data,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
  } catch (e) {
    return e;
  }
};

export const acrhiveUser = async (userId: string): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/users/${userId}/archive`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
