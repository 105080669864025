import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { OmInput } from 'src/shared/components';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';

export const LowerCategoryForm = function ({
  formik,
  upperCategories,
  lowerCategoryId,
  isSubmitting
}) {
  const { t } = useTranslation();

  const newUpperCategories = upperCategories?.data?.filter(
    (item) => !item?.restricted
  );

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={newUpperCategories || []}
            onChange={(_e, newValue) =>
              formik.setFieldValue('upperCategory', newValue ? newValue.id : '')
            }
            value={
              upperCategories?.data?.find(
                (category) => category.id === formik.values.upperCategory
              ) || null
            }
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                required
                fullWidth
                {...params}
                label={t('inputs.label.upperCategory')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.title')}
            name="title"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <OmInput
            formik={formik}
            label={t('inputs.label.priority')}
            name="priority"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <OmInput
            multiline={true}
            rows={3}
            formik={formik}
            label={t('inputs.label.description')}
            name="description"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          fullWidth
          color="primary"
          sx={{ width: { xs: '100%', md: 250 } }}
        >
          {t(`buttons.${lowerCategoryId ? 'update' : 'addLowerCategory'}`)}
        </LoadingButton>
      </Grid>
    </Box>
  );
};
