import PageWrapper from 'src/shared/components/page-wrapper';
import { Card, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLowerCategory } from '../hooks/use-lower-category';
import { LowerCategoryDetails } from '../components/lower-category-details';
import { closeDrawerPanel } from 'src/shared/store/slices/calendar';

export const LowerCategoryDetailsContainer = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useLowerCategory();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('lowerCategories.name'),
      href: '/dashboard/lower-categories'
    },
    {
      label: t('lowerCategories.lowerCategory'),
      href: '/dashboard/lower-category'
    }
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('lowerCategories.lowerCategory')}: ${
        data?.title ? data?.title : ''
      }`}
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <>
          <Grid container>
            <LowerCategoryDetails data={data} />
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};
