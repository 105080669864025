import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import EditIcon from '@mui/icons-material/Edit';
// import ArchiveIcon from '@mui/icons-material/Archive';
// import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { Link } from 'react-router-dom';
// import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
// import { useMakeDeleteUpperCategory } from '../hooks/use-make-delete-upper-category';

export const UpperCategoriesTableActionButtons: FC<{
  upperCategory;
}> = ({ upperCategory }) => {
  const { t } = useTranslation();
  // const { makeDeleteUpperCategory } = useMakeDeleteUpperCategory();
  // const { dispatch } = useContext(ConfirmationModalContext);

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/upper-categories/${upperCategory.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton
            color="primary"
            component={Link}
            to={`/dashboard/upper-categories/edit-upper-category/${upperCategory.id}`}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title={t('tooltips.deletUpperCategory')} arrow>
          <IconButton
            disabled
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    makeDeleteUpperCategory({
                      type: 'upper-categories',
                      genericId: upperCategory.id
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deletUpperCategory'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
      </Typography>
    </>
  );
};
