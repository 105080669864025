export function lessonFormMapper(values) {
  const student = {
    group: values.group?.id,
    teacher: values.teacher,
    present: ['6517196b5d8a8439f44fd7d8'],
    absent: ['6517196b5d8a8439f44fd7d8'],
    subject: values.subject,
    room: values.room,
    isExtra: values.isExtra,
    topic: values.topic
  };

  return student;
}

export function lessonDetailsMapper(values) {
  const group = {
    id: values?.id,
    group: values?.group,
    present: values?.present,
    absent: values?.absent,
    teacher: values?.teacher,
    subject: values?.subject,
    room: values?.room,
    topic: values?.topic,
    isExtra: values?.isExtra
  };
  return group;
}

export function lessonsMapper(data) {
  const lessons = data?.data.map((lesson) => lessonDetailsMapper(lesson));
  return lessons;
}
