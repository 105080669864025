import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useGuardians } from 'src/modules/guardians/hooks/use-guardians';
import { usePackages } from 'src/modules/packages/hooks/use-packages';
import { addAndUpdateSubUser } from 'src/modules/user-services/services';
import { useStudent } from './use-student';
import { studentFormMapper } from '../mappers';
import { REGULAR_EXPRESSIONS } from 'src/shared/costants/constants';
import dayjs from 'dayjs';

export const useStudentForm = function () {
  const { t } = useTranslation();
  const { handleMessage } = useContext(AlertContext);
  const { studentId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: guardiansData } = useGuardians(false);
  const { data: packagesData } = usePackages(false);
  const { data } = useStudent();

  const { mutate: addAndUpdateFromMutate, isLoading: isSubmitting } =
    useMutation('add-student', addAndUpdateSubUser, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('students-get-all');
          if (!studentId) navigate('/dashboard/students');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('yup.required.name')),
    surname: Yup.string().required(t('yup.required.surname')),
    guardian: Yup.object().required(t('yup.required.guardian')),
    schoolAdmittionYear: Yup.string().required(
      t('yup.required.schoolAdmittionYear')
    ),
    gender: Yup.string().required(t('yup.required.gender')),
    phoneNumbers: Yup.string().when('isForeign', {
      is: false,
      then: Yup.string()
        .min(9, t('yup.required.minPhoneNumber'))
        .max(9, t('yup.required.maxPhoneNumber'))
        .matches(REGULAR_EXPRESSIONS.phone, t('yup.required.validPhoneNumber'))
        .required(),
      otherwise: Yup.string().required()
    }),
    relationship: Yup.string().when('guardian', {
      is: (value) => !!value,
      then: Yup.string().required(t('yup.required.relationship')),
      otherwise: Yup.string()
    })
  });
  const isForeign = data && data?.phoneNumbers.toString().slice(0, 3) !== '994';
  const phoneNumber = isForeign
    ? data?.phoneNumbers.toString()
    : data?.phoneNumbers.toString().slice(3);

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      surname: data?.surname || '',
      patronymic: data?.patronymic || '',
      description: data?.description || '',
      note: '',
      phoneNumbers: phoneNumber || '',
      email: data?.email || '',
      dateOfBirth: data?.dateOfBirth ? dayjs(data?.dateOfBirth) : null,
      groupDP: data?.groupDP || '',
      schoolAdmittionYear: data?.schoolAdmittionYear || '',
      guardian: data?.guardian || '',
      relationship: data?.relationship || '',
      package: data?.package || '',
      isForeign: isForeign || false,
      gender: data?.gender || ''
    },
    onSubmit: (values) => {
      if (values) {
        const mappedStudent = studentFormMapper(values);

        if (studentId) {
          addAndUpdateFromMutate({
            data: mappedStudent,
            userId: studentId
          });
        } else {
          addAndUpdateFromMutate({ data: mappedStudent, role: 'student' });
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    isSubmitting,
    guardiansData,
    packagesData,
    studentId,
    data
  };
};
